import { getLogger } from '@/idv/utils';
import { useRequestErrorToast } from '@onefootprint/hooks';
import { useRequestError } from '@onefootprint/request';
import type { AuthMethodKind, ChallengeKind } from '@onefootprint/request-types';
import { useReducer, useState } from 'react';
import Challenge from './components/challenge';
import CollectEmail from './components/collect-email';
import CollectPhone from './components/collect-phone';
import DifferentAccountOption from './components/different-account-option';
import Init from './components/init';
import LinkExpiredError from './components/link-expired-error';
import LoginChallenge from './components/login-challenge/login-challenge';
import type { DoneArgs, InitArgs } from './identify.types';
import loadNextRequirement from './utils/load-requirements';
import { type NextAction, getInitialState, reducer, wasPasskeyRegistered } from './utils/reducer';

const { logError } = getLogger({ location: 'identify' });

type IdentifyProps = {
  onDone: (args: DoneArgs) => void;
  initArgs: InitArgs;
};

const Identify = ({ onDone, initArgs }: IdentifyProps) => {
  const { bootstrapData, ...restOfInitArgs } = initArgs;
  const initialState = getInitialState(bootstrapData);
  const [state, dispatch] = useReducer(reducer, initialState);
  const showRequestErrorToast = useRequestErrorToast();
  const { getErrorCode } = useRequestError();
  const [isExpiredError, setIsExpiredError] = useState(false);

  const handleAdvanceToNext = async (args: Partial<Omit<NextAction, 'type'>>, delayMs?: number) => {
    const identifyToken = args.identifyToken || state.identifyToken;
    const nextRequirement = await loadNextRequirement(identifyToken);

    const next = () => {
      if (nextRequirement.kind === 'handleNextRequirement') {
        dispatch({ type: 'next', ...args, requirement: nextRequirement.requirement });
      } else if (nextRequirement.kind === 'done') {
        const { email, phoneNumber } = state;
        const isPasskeyRegistered = wasPasskeyRegistered(state);
        onDone({ authToken: nextRequirement.authToken, email, phoneNumber, isPasskeyRegistered });
      }
    };
    if (delayMs) {
      setTimeout(next, delayMs);
    } else {
      next();
    }
  };

  const handleReset = !state.isLoggedIn
    ? () => {
        dispatch({ type: 'resetToLoginWithDifferentAccount' });
      }
    : undefined;
  const onError = (location: string, error: unknown) => {
    logError(`Error in ${location}:`, error);
    showRequestErrorToast(error);
    const errorCode = getErrorCode(error);
    const isExpiredError = errorCode === 'E118';
    if (isExpiredError) {
      setIsExpiredError(true);
    }
  };
  const onPrev = state.requirementHistory.length > 0 ? () => dispatch({ type: 'prev' }) : undefined;

  if (isExpiredError) {
    return <LinkExpiredError />;
  }

  const { requirement, identifyToken } = state;
  const context = { state, onError, initArgs: restOfInitArgs };

  if (!identifyToken || !requirement) {
    return <Init context={context} onDone={identifyToken => handleAdvanceToNext({ identifyToken })} />;
  }
  if (requirement.kind === 'collect_data' && requirement.cdo === 'email') {
    return <CollectEmail context={context} onPrev={onPrev} onDone={email => handleAdvanceToNext({ email })} />;
  }
  if (requirement.kind === 'collect_data' && requirement.cdo === 'phone_number') {
    return (
      <CollectPhone context={context} onPrev={onPrev} onDone={phoneNumber => handleAdvanceToNext({ phoneNumber })} />
    );
  }
  if (requirement.kind === 'challenge') {
    const challengeKindForAuthMethod: Record<AuthMethodKind, ChallengeKind> = {
      email: 'email',
      phone: 'sms',
      passkey: 'biometric',
    };
    const challengeKind = challengeKindForAuthMethod[requirement.authMethod];
    return (
      <>
        <Challenge
          context={context}
          challengeKind={challengeKind}
          onDone={delayMs => handleAdvanceToNext({}, delayMs)}
          onPrev={onPrev}
          // Re-render the component when the challenge kind changes
          key={requirement.authMethod}
        >
          <DifferentAccountOption onLoginWithDifferentAccount={handleReset} context={context} />
        </Challenge>
      </>
    );
  }
  if (requirement.kind === 'login') {
    return (
      <LoginChallenge
        context={context}
        requirement={requirement}
        onDone={delayMs => handleAdvanceToNext({}, delayMs)}
        onPrev={onPrev}
        onReset={handleReset}
      />
    );
  }
};

export default Identify;
