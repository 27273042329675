import { IcoForbid40 } from '@onefootprint/icons';
import { EmptyState } from '@onefootprint/ui';
import { useTranslation } from 'react-i18next';
import { NavigationHeader } from '../../layout';

const LinkExpiredError = () => {
  const { t } = useTranslation('idv', { keyPrefix: 'global.errors.expired' });

  return (
    <>
      <NavigationHeader leftButton={{ variant: 'close' }} />
      <EmptyState description={t('subtitle')} iconComponent={IcoForbid40} title={t('title')} />
    </>
  );
};

export default LinkExpiredError;
