import { cx } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export type LoadingSpinnerProps = {
  className?: string;
  color?: 'accent' | 'secondary' | 'tertiary' | 'primary' | 'quinary' | 'translucid';
  ariaLabel?: string;
  size?: 'compact' | 'default' | 'large';
};

const LoadingSpinner = ({ className, color = 'primary', ariaLabel, size = 'default' }: LoadingSpinnerProps) => {
  const { t } = useTranslation('ui', { keyPrefix: 'components.loading-indicator' });

  return (
    <div
      className={cx(
        'flex items-center justify-center',
        {
          'text-accent': color === 'accent',
          'text-secondary': color === 'secondary',
          'text-tertiary': color === 'tertiary',
          'text-primary': color === 'primary' || color === 'translucid',
          'text-quinary': color === 'quinary',
        },
        {
          'w-3 h-3': size === 'compact',
          'w-4 h-4': size === 'default',
          'w-5 h-5': size === 'large',
        },
        className,
      )}
      aria-label={ariaLabel || t('aria-label-default')}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={50}
      tabIndex={0}
    >
      <motion.svg
        className="w-full h-full"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Number.POSITIVE_INFINITY, ease: 'linear' }}
      >
        <circle
          cx={16}
          cy={16}
          r={12}
          className={cx('stroke-gray-100', {
            'stroke-purple-100': color === 'accent',
            'stroke-purple-600': color === 'quinary',
            'stroke-gray-100': color === 'secondary' || color === 'tertiary' || color === 'primary',
            'stroke-primary/10': color === 'translucid',
          })}
          strokeWidth={4}
          fill="none"
          stroke="currentColor"
          strokeOpacity={color === 'translucid' ? 0.2 : 1}
        />
        <motion.circle
          cx={16}
          cy={16}
          r={12}
          stroke="currentColor"
          strokeWidth={4}
          strokeLinecap="round"
          fill="none"
          className="stroke-primary"
          strokeDasharray="75 75"
          animate={{
            strokeDashoffset: [75, 0, -75],
            rotate: [0, 360],
          }}
          transition={{
            duration: 4,
            repeat: Number.POSITIVE_INFINITY,
            ease: 'linear',
          }}
          strokeOpacity={color === 'translucid' ? 0.3 : 1}
        />
      </motion.svg>
    </div>
  );
};

export default LoadingSpinner;
