import type { DIMetadata } from '@/idv/types';
import type { IdentifiedUser } from '@onefootprint/request-types';
import { IdDI } from '@onefootprint/types';

export const getDisplayPhone = ({
  user,
  phoneNumber,
}: { user?: IdentifiedUser; phoneNumber?: DIMetadata<string> }): string | undefined => {
  const value = phoneNumber?.value;
  const isLoginChallenge = !!user;
  if (isLoginChallenge && !user.matchingFps?.includes(IdDI.phoneNumber)) {
    // The user was identified via something other the phone that was passed in, so we don't know
    // that the phoneNumber we have is the one on the vault. Instead, we'll use the scrubbed one
    // that belongs to the user
    return (
      user?.scrubbedPhone
        ?.replace(/\*/g, '•')
        // Replace spaces with non-breaking spaces and hyphens with non-breaking hyphens
        .replace(/ /g, '\u00A0')
        .replace(/-/g, '\u2011')
    );
  }
  // Either we located the user with this phone number, or we're initiating a signup challenge to
  // this phone number
  const match = value?.match(/(\+\d{1,3} )?(.*)/);
  if (!match) {
    return undefined;
  }
  const countryCode = match[1] ? match[1] : '';
  const number = match[2];
  // Replace spaces with non-breaking spaces and hyphens with non-breaking hyphens
  return (countryCode + number).replace(/ /g, '\u00A0').replace(/-/g, '\u2011');
};

export const getDisplayEmail = ({
  user,
  email,
}: { user?: IdentifiedUser; email?: DIMetadata<string> }): string | undefined => {
  const value = email?.value;
  const isLoginChallenge = !!user;

  if (isLoginChallenge && !user.matchingFps?.includes(IdDI.email)) {
    // The user was identified via something other the email that was passed in, so we don't know
    // that the email we have is the one on the vault. Instead, we'll use the scrubbed email if
    // available
    return user?.scrubbedEmail?.replace(/\*/g, '•');
  }
  // Either we located the user with this email, or we're initiating a signup challenge to this email
  return value;
};
