import { useRequestErrorToast } from '@onefootprint/hooks';

import { getBiometricChallengeResponse, getLogger } from '@/idv/utils';
import { postHostedIdentifySessionChallenge, postHostedIdentifySessionChallengeVerify } from '@onefootprint/axios';
import { useToast } from '@onefootprint/ui';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const { logError, logWarn } = getLogger({ location: 'run-passkey' });

type UseRunPasskeyProps = {
  onDone?: (delayMs: number) => Promise<void>;
};

export const runPasskeyChallenge = async (identifyToken: string) => {
  const headers = { 'X-Fp-Authorization': identifyToken };
  const {
    data: { challengeData, error },
  } = await postHostedIdentifySessionChallenge({
    headers,
    body: { challengeKind: 'biometric' },
    throwOnError: true,
  });
  if (error) {
    throw error;
  }
  const { biometricChallengeJson, challengeKind } = challengeData;

  if (challengeKind !== 'biometric' || !biometricChallengeJson) {
    logError('Received incorrect challenge after requesting login biometric challenge');
    return;
  }

  let challengeResponse;
  try {
    challengeResponse = await getBiometricChallengeResponse(biometricChallengeJson);
  } catch (error) {
    throw { stage: 'get-challenge-response', error };
  }

  await postHostedIdentifySessionChallengeVerify({
    headers,
    body: { challengeResponse },
    throwOnError: true,
  });
};

const useRunPasskey = ({ onDone }: UseRunPasskeyProps) => {
  const { t } = useTranslation('identify', {
    keyPrefix: 'passkey-challenge',
  });
  const showRequestErrorToast = useRequestErrorToast();
  const toast = useToast();

  return useMutation({
    mutationFn: async (identifyToken: string) => {
      await runPasskeyChallenge(identifyToken);
      await onDone?.(0);
    },
    onError: error => {
      if (isGetChallengeResponseError(error)) {
        // Special error handling when the error comes from device's webauthn API
        const e = error.error;
        logWarn(`Unable to generate biometric challenge response ${typeof e === 'string' ? e : JSON.stringify(e)}`, e);
        toast.show({
          title: t('error.title'),
          description: t('error.description'),
          variant: 'error',
        });
      } else {
        logError('Error while running passkey challenge:', error);
        showRequestErrorToast(error);
      }
    },
  });
};

type StagedError = {
  stage: string;
  error: unknown;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const isGetChallengeResponseError = (error: any): error is StagedError => {
  return typeof error === 'object' && 'stage' in error && error.stage === 'get-challenge-response';
};

export default useRunPasskey;
