import type { FormEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { LoginChallengeProps } from '../login-challenge';

import { isBiometric } from '@/idv/utils';
import type { ChallengeKind } from '@onefootprint/request-types';
import { Button, RadioSelect, Stack } from '@onefootprint/ui';
import { IdentifyVariant } from '../../../identify.types';
import getHeader from '../../../utils/get-header';
import Challenge from '../../challenge';
import useRunPasskey from '../hooks/run-passkey';
import { getChallengeTitleByKind, getMethods } from './utils';

enum Screen {
  challengeSelector = 'challengeSelector',
  challenge = 'challenge',
}

type ChallengeSelectOrPasskeyProps = Omit<LoginChallengeProps, 'onReset'> & {
  children: React.ReactNode;
};

const ChallengeSelectOrPasskey = ({
  context,
  requirement,
  onPrev,
  onDone,
  children,
}: ChallengeSelectOrPasskeyProps) => {
  const {
    initArgs: { device, logoConfig, variant },
    state: { phoneNumber, email, identifyToken },
  } = context;
  const { t } = useTranslation('identify');
  const [selectedChallenge, setSelectedChallenge] = useState<ChallengeKind | undefined>(undefined);
  const [screen, setScreen] = useState<Screen>(Screen.challengeSelector);

  const runPasskey = useRunPasskey({ onDone });

  const methodOptions = useMemo(() => {
    const ctx = { user: requirement.user, phoneNumber, email };
    const titleMap = getChallengeTitleByKind(t, ctx);
    return getMethods(requirement.user, device, titleMap);
  }, [t, requirement, device, phoneNumber, email]);

  useEffect(() => {
    if (methodOptions.length && !selectedChallenge) {
      setSelectedChallenge(methodOptions[0].value);
    }
  }, [selectedChallenge, methodOptions]);

  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!selectedChallenge) {
      return;
    }
    if (isBiometric(selectedChallenge)) {
      runPasskey.mutate(identifyToken);
    } else {
      setScreen(Screen.challenge);
    }
  };

  if (screen === Screen.challenge && selectedChallenge) {
    return (
      <Challenge
        context={context}
        onPrev={() => setScreen(Screen.challengeSelector)}
        onDone={onDone}
        user={requirement.user}
        challengeKind={selectedChallenge}
        fromChallengeSelector
      />
    );
  }

  const Header = getHeader(logoConfig, onPrev);
  const headerSubtitle =
    variant !== IdentifyVariant.updateLoginMethods
      ? t('challenge-select-or-biometric.log-in-options')
      : t('challenge-select-or-biometric.log-in-to-modify-details');
  const shouldShowWelcomeBack = !requirement.user.isUnverified;
  const title = shouldShowWelcomeBack
    ? t('challenge-select-or-biometric.welcome-back-title')
    : t('challenge-select-or-biometric.verify-title');
  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" gap={6}>
        <Header subtitle={headerSubtitle} title={title} />
        <RadioSelect
          onChange={value => setSelectedChallenge(value as ChallengeKind)}
          options={methodOptions}
          size="compact"
          value={selectedChallenge || ''}
        />
        <Button
          disabled={!selectedChallenge}
          fullWidth
          loading={runPasskey.isPending}
          type="submit"
          size="large"
          data-dd-action-name="challenge:continue"
        >
          {t('continue')}
        </Button>
        {children}
      </Stack>
    </form>
  );
};

export default ChallengeSelectOrPasskey;
