import type { IdentifyRequirementLogin } from '@onefootprint/request-types';
import { Stack } from '@onefootprint/ui';
import { useState } from 'react';
import { type Context, IdentifyVariant } from '../../identify.types';
import Challenge from '../challenge';
import DifferentAccountOption from '../different-account-option';
import ChallengeSelectOrPasskey from './components/challenge-select-or-passkey';
import LoginWithUnverifiedEmail from './components/login-with-unverified-email';
import TryAnotherWay from './components/try-another-way';
import { getAvailableMethods } from './components/utils';

export type LoginChallengeProps = {
  context: Omit<Context, 'identifyToken'>;
  requirement: IdentifyRequirementLogin;
  onDone: (delayMs?: number) => Promise<void>;
  onReset?: () => void;
  onPrev?: () => void;
};

const isUpdateLoginFlow = (v: unknown): v is IdentifyVariant.updateLoginMethods =>
  v === IdentifyVariant.updateLoginMethods;

enum Screen {
  Challenge = 'challenge',
  LoginWithUnverifiedEmail = 'login-with-unverified-email',
}

const LoginChallenge = ({ context, requirement, onDone, onPrev, onReset }: LoginChallengeProps) => {
  const loginMethods = getAvailableMethods(requirement.user, context.initArgs.device);
  const isOnlyBiometric = loginMethods.length === 1 && loginMethods[0] === 'biometric';
  const shouldShowCallengeSelector =
    isUpdateLoginFlow(context.initArgs.variant) || loginMethods.length > 1 || isOnlyBiometric;
  const [screen, setScreen] = useState<Screen>(Screen.Challenge);

  if (screen === Screen.LoginWithUnverifiedEmail) {
    return (
      <LoginWithUnverifiedEmail
        context={context}
        requirement={requirement}
        onDone={onDone}
        onPrev={() => setScreen(Screen.Challenge)}
      />
    );
  }

  if (shouldShowCallengeSelector) {
    return (
      <ChallengeSelectOrPasskey context={context} requirement={requirement} onDone={onDone} onPrev={onPrev}>
        <TryAnotherWay requirement={requirement} onClick={() => setScreen(Screen.LoginWithUnverifiedEmail)} />
        <DifferentAccountOption onLoginWithDifferentAccount={onReset} context={context} />
      </ChallengeSelectOrPasskey>
    );
  }
  const challengeKind = loginMethods[0];
  return (
    <>
      <Challenge
        context={context}
        onDone={onDone}
        onPrev={onPrev}
        user={requirement.user}
        challengeKind={challengeKind}
      >
        <Stack direction="column" gap={6}>
          <TryAnotherWay requirement={requirement} onClick={() => setScreen(Screen.LoginWithUnverifiedEmail)} />
          <DifferentAccountOption onLoginWithDifferentAccount={onReset} context={context} />
        </Stack>
      </Challenge>
    </>
  );
};

export default LoginChallenge;
