import { ChallengeKind } from '@onefootprint/types';
import { useState } from 'react';
import Challenge from '../../challenge';
import type { LoginChallengeProps } from '../login-challenge';
import PhoneKbaChallenge from './phone-kba-challenge';

enum Screen {
  kba = 'kba',
  challenge = 'challenge',
}

type LoginWithUnverifiedEmailProps = Omit<LoginChallengeProps, 'onReset'> & {
  onPrev: () => void;
};

const LoginWithUnverifiedEmail = ({ context, requirement, onDone, onPrev }: LoginWithUnverifiedEmailProps) => {
  const [screen, setScreen] = useState<Screen>(Screen.kba);

  if (screen === Screen.kba) {
    return (
      <PhoneKbaChallenge
        requirement={requirement}
        context={context}
        onPrev={onPrev}
        onSuccess={() => {
          setScreen(Screen.challenge);
        }}
      />
    );
  }

  return (
    <>
      <Challenge
        context={context}
        onDone={onDone}
        onPrev={() => setScreen(Screen.kba)}
        challengeKind={ChallengeKind.email}
      />
    </>
  );
};

export default LoginWithUnverifiedEmail;
