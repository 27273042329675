import type { DIMetadata } from '@/idv/types';
import { getLogger } from '@/idv/utils';
import type { IdentifyRequirement } from '@onefootprint/request-types';
import type { IdentifyBootstrapData } from '../identify.types';
import validateBootstrapData from './validate-bootstrap-data';

const { logError } = getLogger({ location: 'identify-reducer' });

export type State = {
  identifyToken: string;
  requirement?: IdentifyRequirement;
  /** The stack of previous requirements that can be re-visited using the back button. */
  requirementHistory: IdentifyRequirement[];
  email?: DIMetadata<string>;
  phoneNumber?: DIMetadata<string>;
  /** True if a challenge has already been completed. */
  isLoggedIn: boolean;
  /** True if a passkey is registered for the logged-in user. */
  isPasskeyRegistered?: boolean;
};

export type NextAction = {
  type: 'next';
  requirement: IdentifyRequirement;
  identifyToken?: string;
  email?: string;
  phoneNumber?: string;
};

export type Action = NextAction | { type: 'prev' } | { type: 'resetToLoginWithDifferentAccount' };

export const getInitialState = (bootstrapData?: IdentifyBootstrapData): State => {
  const { email, phoneNumber } = validateBootstrapData(bootstrapData);
  return {
    identifyToken: '',
    requirement: undefined,
    requirementHistory: [],
    email,
    phoneNumber,
    isLoggedIn: false,
  };
};

const computeNextVaultData = (current?: DIMetadata<string>, value?: string) => {
  // Don't support clearing the value
  if (!value) return current;
  // If the value changes, clear the `isBootstrap` flag
  const isBootstrap = current?.value === value && current.isBootstrap;
  return { value, isBootstrap };
};

export const wasPasskeyRegistered = (state: State) => {
  const isPasskeyRegisteredCurrentReq =
    state.requirement?.kind === 'login' && state.requirement?.user.availableChallengeKinds.includes('biometric');
  return state.isPasskeyRegistered || isPasskeyRegisteredCurrentReq;
};

/** Unique key for a requirement that can be used to determine if we receive duplicate requirements */
const requirementKey = (requirement?: IdentifyRequirement) => {
  if (!requirement) return undefined;
  if (requirement.kind === 'challenge') {
    return `${requirement.kind}-${requirement.authMethod}`;
  }
  if (requirement.kind === 'collect_data') {
    return `${requirement.kind}-${requirement.cdo}`;
  }
  return requirement.kind;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'next': {
      const identifyToken = action.identifyToken || state.identifyToken;
      const email = computeNextVaultData(state.email, action.email);
      const phoneNumber = computeNextVaultData(state.phoneNumber, action.phoneNumber);
      const requirement = action.requirement;

      let requirementHistory = [...state.requirementHistory];
      let isLoggedIn = state.isLoggedIn;
      const currentRequirementKind = state.requirement?.kind;
      if (currentRequirementKind === 'challenge' || currentRequirementKind === 'login') {
        // Don't support going back to a challenge screen since a challenge cannot be undone after the user has logged in
        isLoggedIn = true;
        requirementHistory = [];
      } else if (state.requirement) {
        requirementHistory = [state.requirement, ...requirementHistory];
      }

      if (requirementKey(state.requirement) === requirementKey(requirement)) {
        const requirements = JSON.stringify({
          lastRequirement: state.requirement,
          nextRequirement: requirement,
        });
        logError(`User is stuck on an identify requirement: ${requirements}`);
      }

      return {
        identifyToken,
        requirement,
        requirementHistory,
        email,
        phoneNumber,
        isLoggedIn,
        isPasskeyRegistered: wasPasskeyRegistered(state),
      };
    }
    case 'prev': {
      const { requirementHistory, requirement, ...restOfState } = state;
      const [prevRequirement, ...restOfLastHandledRequirements] = requirementHistory;
      return { ...restOfState, requirementHistory: restOfLastHandledRequirements, requirement: prevRequirement };
    }
    case 'resetToLoginWithDifferentAccount': {
      return getInitialState(undefined);
    }
    default:
      return state;
  }
};
