import type { Icon } from '@onefootprint/icons';
import { IcoEmail16, IcoFaceid16, IcoSmartphone16 } from '@onefootprint/icons';
import type { TFunction } from 'i18next';

import type { DeviceInfo } from '@/idv/hooks';
import type { DIMetadata } from '@/idv/types';
import type { ChallengeKind, IdentifiedUser } from '@onefootprint/request-types';
import { IdentifyVariant } from '../../../identify.types';
import { getDisplayEmail, getDisplayPhone } from '../../../utils/get-display-contact-info';

type T = TFunction<'identify'>;
type TitleMap = Record<ChallengeKind, string | JSX.Element>;

const challengeIcons: Record<ChallengeKind, Icon> = {
  sms: IcoSmartphone16,
  email: IcoEmail16,
  biometric: IcoFaceid16,
  sms_link: IcoSmartphone16,
};
const challengePriority: Record<ChallengeKind, number> = {
  biometric: 0,
  sms: 1,
  email: 2,
  sms_link: 3,
};

const sortChallenges = (a: ChallengeKind, b: ChallengeKind) => challengePriority[a] - challengePriority[b];

export const getSubTitle = (t: T, variant: IdentifyVariant): string =>
  variant !== IdentifyVariant.updateLoginMethods
    ? t('challenge-select-or-biometric.log-in-options')
    : t('challenge-select-or-biometric.log-in-to-modify-details');

export const getAvailableMethods = (user: IdentifiedUser, device: DeviceInfo) => {
  const { type, hasSupportForWebauthn } = device;
  const kinds = [...(user?.availableChallengeKinds || [])];

  return kinds.sort(sortChallenges).filter(kind => {
    if (kind === 'biometric') {
      return type === 'mobile' ? hasSupportForWebauthn : hasSupportForWebauthn && user?.hasSyncablePasskey;
    }
    if (kind === 'sms_link') {
      return false;
    }

    return true;
  });
};

export const getMethods = (user: IdentifiedUser, device: DeviceInfo, titleMap: TitleMap) => {
  return getAvailableMethods(user, device).map(kind => ({
    IconComponent: challengeIcons[kind],
    title: titleMap[kind],
    value: kind,
  }));
};

function getChallengeTitleEmail(t: T, context: Context): string | JSX.Element {
  const displayEmail = getDisplayEmail(context);
  const sendTo = t('challenge-select-or-biometric.send-code-to');
  return displayEmail ? (
    <>
      {sendTo} <span data-dd-privacy="mask">{displayEmail}</span>
    </>
  ) : (
    t('challenge-select-or-biometric.send-code-via-email')
  );
}

const getChallengeTitlePhone = (t: T, context: Context): string | JSX.Element => {
  const displayPhone = getDisplayPhone(context);
  const sendTo = t('challenge-select-or-biometric.send-code-to');
  return displayPhone ? (
    <>
      {sendTo} <span data-dd-privacy="mask">{displayPhone}</span>
    </>
  ) : (
    t('challenge-select-or-biometric.send-code-via-sms')
  );
};

type Context = {
  user?: IdentifiedUser;
  email?: DIMetadata<string>;
  phoneNumber?: DIMetadata<string>;
};

export const getChallengeTitleByKind = (t: T, context: Context): TitleMap => ({
  sms: getChallengeTitlePhone(t, context),
  sms_link: getChallengeTitlePhone(t, context),
  email: getChallengeTitleEmail(t, context),
  biometric: t('challenge-select-or-biometric.passkey'),
});
