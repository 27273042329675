import type { IdentifyRequirementLogin } from '@onefootprint/request-types';
import { AuthMethodKind } from '@onefootprint/types';
import { LinkButton, Text } from '@onefootprint/ui';
import { useTranslation } from 'react-i18next';

const TryAnotherWay = ({
  requirement,
  onClick,
}: {
  requirement: IdentifyRequirementLogin;
  onClick: () => void;
}) => {
  const { t } = useTranslation('identify');
  const { user } = requirement;

  const hasAuthMethodUnverifiedEmail = user?.authMethods.some(m => m.kind === AuthMethodKind.email && !m.isVerified);
  const isNypid = !!user?.isUnverified;
  if (!hasAuthMethodUnverifiedEmail || isNypid) {
    return null;
  }

  return (
    <div className="flex flex-row gap-1 items-center justify-center w-full flex-wrap">
      <Text tag="span" whiteSpace="nowrap" color="tertiary" isPrivate variant="label-3">
        {t('no-access-methods-label')}&nbsp;
      </Text>
      <LinkButton variant="label-3" onClick={onClick} data-dd-action-name={`inline-action:${t('send-code-to-email')}`}>
        {t('send-code-to-email')}
      </LinkButton>
    </div>
  );
};

export default TryAnotherWay;
