import { PinInput, Stack, Text } from '@onefootprint/ui';
import styled, { css } from 'styled-components';

import type { ResendButtonProps } from './components/resend-button';
import ResendButton from './components/resend-button';
import Success from './components/success';
import Verifying from './components/verifying';

export type PinFormProps = Omit<ResendButtonProps, 'texts'> & {
  children?: JSX.Element;
  hasError?: boolean;
  isPending?: boolean;
  isSuccess?: boolean;
  isVerifying?: boolean;
  onComplete: (code: string) => void;
  title?: string;
  texts: ResendButtonProps['texts'] & {
    codeError: string;
    success: string;
    verifying: string;
  };
};

const PinForm = ({
  children,
  hasError,
  isPending,
  isResendLoading,
  isSuccess,
  isVerifying,
  onComplete,
  onResend,
  resendDisabledUntil,
  texts,
  title,
}: PinFormProps) => {
  if (isSuccess) return <Success text={texts.success} />;
  if (isVerifying) return <Verifying text={texts.verifying} />;

  return (
    <StyledForm autoComplete="off" role="presentation" data-pending={!!isPending}>
      {title && (
        <Text isPrivate variant="body-2" color="secondary" tag="h3">
          {title}
        </Text>
      )}
      <PinInput
        disabled={isPending}
        onComplete={onComplete}
        hasError={hasError}
        hint={hasError ? texts.codeError : undefined}
        testID="verification-form-pin-input"
        autoFocus
      />
      <Stack direction="column" width="100%" align="center" justify="center" gap={3} marginBottom={5}>
        <ResendButton
          isResendLoading={isResendLoading}
          onResend={onResend}
          resendDisabledUntil={resendDisabledUntil}
          texts={{
            resendCountDown: texts.resendCountDown,
            resendCta: texts.resendCta,
          }}
        />
        {children}
      </Stack>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[5]};
    justify-content: center;
    align-items: center;
    text-align: center;
  `}
`;

export default PinForm;
