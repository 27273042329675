import { COUNTRIES } from '@onefootprint/global-constants';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import { useL10nContext } from '@/idv/components/l10n-provider';
import { checkIsPhoneValid } from '@/idv/utils';
import { postHostedIdentifySessionKbaMutation } from '@onefootprint/axios';
import { useMutation } from '@tanstack/react-query';
import getHeader from '../../../utils/get-header';
import PhonePageStructure from '../../phone-page-structure';
import type { LoginChallengeProps } from '../login-challenge';

type PhoneKbaChallengeProps = {
  requirement: LoginChallengeProps['requirement'];
  context: Omit<LoginChallengeProps['context'], 'onPrev'>;
  onPrev: () => void;
  onSuccess: () => void;
};

const PhoneKbaChallenge = ({ requirement, context, onSuccess, onPrev }: PhoneKbaChallengeProps) => {
  const {
    state: { phoneNumber },
    onError,
    initArgs: { config, logoConfig },
  } = context;
  const { t } = useTranslation('identify');
  const headers = { 'X-Fp-Authorization': context.state.identifyToken };
  const mutIdentifyKba = useMutation({
    ...postHostedIdentifySessionKbaMutation({ headers }),
    onError: err => {
      onError('phone-kba-challenge', err);
    },
    onSuccess,
  });
  const l10n = useL10nContext();
  const options = COUNTRIES;

  const handlePhoneValidation = (phone: string) => checkIsPhoneValid(phone, config?.isLive === false);

  const handleSubmit = (phoneFromForm: string) => {
    mutIdentifyKba.mutate({ body: { 'id.phone_number': phoneFromForm } });
  };

  const Header = getHeader(logoConfig, onPrev);
  return (
    <PhonePageStructure
      countries={options}
      defaultPhone={phoneNumber?.value}
      Header={Header}
      isLoading={mutIdentifyKba.isPending}
      l10n={l10n}
      onSubmit={mutIdentifyKba.isPending ? noop : handleSubmit}
      phoneValidator={handlePhoneValidation}
      texts={{
        headerTitle: t('confirm-phone-number'),
        headerSubtitle: t('before-email-code-confirmation', {
          number: (requirement.user?.scrubbedPhone || '').slice(-4).replace(/\*/g, '•'),
        }),
        cta: t('continue'),
        emailChangeCta: t('change'),
        phoneInvalid: t('phone-step.form.input-invalid'),
        phoneLabel: t('phone-number'),
        phoneRequired: t('phone-step.form.input-required'),
      }}
    />
  );
};

export default PhoneKbaChallenge;
