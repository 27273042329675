import type { DeviceInfo } from '@/idv/hooks';
import type { DIMetadata } from '@/idv/types';
import type { ObConfigAuth, OverallOutcome, PublicOnboardingConfig } from '@onefootprint/types';
import type { State } from './utils/reducer';

export type LogoConfig = { orgName: string; logoUrl?: string };

export type IdentifyBootstrapData = { email?: string; phoneNumber?: string };

export type InitArgs = {
  initialAuthToken?: string;
  isComponentsSdk?: boolean;
  bootstrapData?: IdentifyBootstrapData;
  config?: PublicOnboardingConfig;
  isLive: boolean;
  device: DeviceInfo;
  obConfigAuth?: ObConfigAuth;
  sandboxId?: string;
  overallOutcome?: OverallOutcome;
  logoConfig?: LogoConfig; // When provided, will render the logo
  variant: IdentifyVariant;
};

export enum IdentifyVariant {
  auth = 'auth',
  updateLoginMethods = 'updateLoginMethods',
  verify = 'verify',
}

export type DoneArgs = {
  authToken: string;
  phoneNumber?: DIMetadata<string>;
  email?: DIMetadata<string>;
  isPasskeyRegistered?: boolean;
};

export type Context = {
  state: State;
  onError: (location: string, error: unknown) => void;
  initArgs: Omit<InitArgs, 'bootstrapData'>;
};

export type ObKeyHeader = { 'X-Onboarding-Config-Key': string };
