import {
  postHostedIdentifySessionChallengeMutation,
  postHostedIdentifySessionChallengeVerify,
} from '@onefootprint/axios';
import { useRequestError } from '@onefootprint/request';
import type {
  ChallengeKind,
  ChallengeVerifyRequest,
  IdentifiedUser,
  UserChallengeData,
} from '@onefootprint/request-types';
import { Stack, useToast } from '@onefootprint/ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Context } from '../identify.types';
import calculateRetryTime from '../queries/get-retry-time';
import { getDisplayEmail, getDisplayPhone } from '../utils/get-display-contact-info';
import getHeader from '../utils/get-header';
import PinForm from './pin-form';

const IS_TEST = process.env.NODE_ENV === 'test';
const SUCCESS_EVENT_DELAY_MS = IS_TEST ? 10 : 1500;

const useChallengeState = () => {
  const { getErrorCode, getErrorContext } = useRequestError();
  const [retryDisabledUntil, setRetryDisabledUntil] = useState<Date | undefined>(undefined);

  const getRetryAfterS = (error: unknown) => {
    const errorCode = getErrorCode(error);
    const isRateLimited = errorCode === 'E104';
    if (isRateLimited) {
      const context = getErrorContext(error);
      return typeof context?.seconds === 'number' ? context.seconds : 0;
    }
    return 0;
  };

  const handleChallengeError = (error: unknown, isFirstAttempt: boolean) => {
    const retryAfterS = getRetryAfterS(error);
    setRetryDisabledUntil(calculateRetryTime(retryAfterS));
    const isRateLimited = Boolean(retryAfterS);
    if (isFirstAttempt && isRateLimited) {
      // Don't show the error toast if we are rate limited on our first attempt
      return;
    }
    return error;
  };
  const handleChallengeSuccess = (challengeData: UserChallengeData) => {
    setRetryDisabledUntil(calculateRetryTime(challengeData.timeBeforeRetryS));
  };

  return {
    retryDisabledUntil,
    handleChallengeSuccess,
    handleChallengeError,
  };
};

type ChallengeProps = {
  children?: JSX.Element;
  challengeKind: ChallengeKind;
  context: Context;
  /** If this is a login challenge, the IdentifiedUser from the requirement. */
  user?: IdentifiedUser;
  onDone: (delayMs?: number) => Promise<void>;
  onPrev?: () => void;
  fromChallengeSelector?: boolean;
};

const Challenge = ({
  children,
  context,
  user,
  onDone,
  onPrev,
  challengeKind,
  fromChallengeSelector,
}: ChallengeProps) => {
  const {
    state: { phoneNumber, email, identifyToken },
    onError,
    initArgs: { logoConfig },
  } = context;
  const { t } = useTranslation('identify');
  const { getErrorCode } = useRequestError();
  const { retryDisabledUntil, handleChallengeError, handleChallengeSuccess } = useChallengeState();

  const headers = { 'X-Fp-Authorization': identifyToken };
  const challengeMutation = useMutation({
    ...postHostedIdentifySessionChallengeMutation({ headers }),
    onSuccess: data => {
      if (data.error) {
        onError('challenge', data.error);
        // Continue as if the challenge was successful - the backend will continue to try sending the
        // challenge even if it errored
      }

      if (challengeMutation.data) {
        // This is a resend, show a toast
        toast.show({
          title: t('pin-verification.success'),
          description: t('pin-verification.new-code-sent-description'),
        });
      }
      handleChallengeSuccess(data.challengeData);
    },
    onError: error => {
      const processedError = handleChallengeError(error, !challengeMutation.data);
      if (processedError) {
        onError('challenge', processedError);
      }
    },
  });
  const verifyMutation = useMutation({
    mutationFn: async (body: ChallengeVerifyRequest) => {
      await postHostedIdentifySessionChallengeVerify({
        headers,
        body: { ...body },
        throwOnError: true,
      });
      await onDone(SUCCESS_EVENT_DELAY_MS);
    },
    onError: error => {
      const errorCode = getErrorCode(error);
      const isChallengeExpired = errorCode === 'E103';
      if (isChallengeExpired) {
        handleRequestChallenge();
      }
      onError('challenge-verify', error);
    },
  });
  const toast = useToast();

  const handleRequestChallenge = () => {
    challengeMutation.mutate({ body: { challengeKind } });
  };
  const handleVerifyPin = (pin: string) => {
    verifyMutation.mutate({ challengeResponse: pin });
  };

  useEffect(() => {
    if (!challengeMutation.data) {
      // Request a new challenge when we visit this page.
      // It may be rate limited if the user went back and forth from the collection screen to PIN input screen.
      handleRequestChallenge();
    }
  }, [challengeMutation.data]);

  let headerTitle;
  let formTitle;
  if (challengeKind === 'email') {
    const displayEmail = getDisplayEmail({ user, email });
    headerTitle = t('email-challenge.verify-title');
    formTitle = displayEmail ? (
      <span data-dd-privacy="mask" data-dd-action-name="Subtitle with email">
        {t('email-challenge.prompt-with-email', { email: displayEmail })}
      </span>
    ) : (
      t('email-challenge.prompt-without-email')
    );
  } else if (challengeKind === 'sms') {
    const displayPhone = getDisplayPhone({ user, phoneNumber });
    headerTitle = t('sms-challenge.verify-title');
    formTitle = displayPhone ? (
      <span data-dd-privacy="mask" data-dd-action-name="Subtitle with phone">
        {t('sms-challenge.prompt-with-phone', { phone: displayPhone })}
      </span>
    ) : (
      t('sms-challenge.prompt-without-phone')
    );
  }
  const shouldShowWelcomeBack = !!user && !user.isUnverified && !fromChallengeSelector;
  if (shouldShowWelcomeBack) {
    headerTitle = t('sms-challenge.welcome-back-title');
  }

  const Header = getHeader(logoConfig, onPrev);

  return (
    <Stack direction="column" align="center" justify="center" gap={8}>
      <Header title={headerTitle} subtitle={formTitle} />
      <PinForm
        hasError={verifyMutation.isError}
        isPending={challengeMutation.isPending}
        isResendLoading={challengeMutation.isPending || verifyMutation.isPending}
        isSuccess={verifyMutation.isSuccess}
        isVerifying={verifyMutation.isPending}
        onComplete={handleVerifyPin}
        onResend={handleRequestChallenge}
        resendDisabledUntil={retryDisabledUntil}
        texts={{
          codeError: t('pin-verification.incorrect-code'),
          resendCountDown: t('pin-verification.resend-countdown'),
          resendCta: t('pin-verification.resend-cta'),
          success: t('pin-verification.success'),
          verifying: t('pin-verification.verifying'),
        }}
      >
        {children}
      </PinForm>
    </Stack>
  );
};

export default Challenge;
